<script setup lang="ts">
import { ref } from "vue";
import { Form } from "vee-validate";

import {
  MeButton,
  MeIcon,
  MeSelectInstallationCard,
  useBreakpoints,
} from "@/libs/metha-components";

import { useUserStore, useLoginStore, useInvoicesStore } from "@/stores";

import { FlatInstallation } from "@/types/InstallationTypes";

import { getSupplierLogo } from "@/utils/supplierlogo";
import { handleError } from "@/utils/handleError";

const loginStore = useLoginStore();
const userStore = useUserStore();
const invoicesStore = useInvoicesStore();
const { mdAndDown } = useBreakpoints();

const selectedInstallation = ref<string | number>();
const selectInstallationError = ref("");

async function handleSubmit() {
  if (!selectedInstallation.value) return;

  try {
    userStore.loadingScreen = true;

    const installation = userStore.installations.find(
      (installation: FlatInstallation) =>
        installation.id === selectedInstallation.value
    );

    if (!installation) return;

    userStore.selectInstallation(installation);

    await invoicesStore.getAllInvoices(installation.id);

    userStore.loadingScreen = false;

    loginStore.redirect();
  } catch (error) {
    handleError(error, (err: string) => {
      selectInstallationError.value = err;
    });
  } finally {
    userStore.loadingScreen = false;
  }
}

function goBackToVerifyCpf() {
  userStore.logOutUser();
  loginStore.changeStatus("verify-cpf");
}
</script>

<template>
  <div class="login__container login-select-installation">
    <p
      class="login-select-installation__return me--link"
      @click="goBackToVerifyCpf"
      v-if="!mdAndDown"
    >
      <span class="login-select-installation__return__icon-container">
        <MeIcon class="login-select-installation__return__icon">
          arrow-down
        </MeIcon>
      </span>
      Voltar
    </p>
    <Form class="login-select-installation__container" @submit="handleSubmit">
      <img
        src="@/assets/images/brand/logo_horizontal.svg"
        alt="Logo da Metha Energia"
        class="login-select-installation__metha-logo"
        width="205"
      />
      <h2 class="login-select-installation__title">Instalações</h2>
      <p class="login-select-installation__register">
        Selecione uma instalação abaixo para continuar
      </p>
      <div
        class="login-select-installation__installations-container me--capitalize"
      >
        <MeSelectInstallationCard
          v-for="{
            id,
            status,
            installation_number,
            address,
            supplier,
          } in userStore.operationalInstallations"
          :key="`login-select-installation-${installation_number}`"
          :status="status"
          :installationNumber="installation_number"
          :address="address"
          :clickable="id !== selectedInstallation"
          :supplierLogo="getSupplierLogo(supplier)"
          :selected="id === selectedInstallation"
          selectable
          @click="selectedInstallation = id"
        />
        <p
          class="login-select-installation--error"
          v-if="selectInstallationError && selectInstallationError.length > 0"
        >
          {{ selectInstallationError }}
        </p>
      </div>
      <MeButton
        block
        class="login-select-installation__submit-button"
        :disabled="!selectedInstallation"
      >
        Entrar
      </MeButton>
      <p
        class="login-select-installation__return me--link"
        @click="goBackToVerifyCpf"
        v-if="mdAndDown"
      >
        <span class="login-select-installation__return__icon-container">
          <MeIcon class="login-select-installation__return__icon">
            arrow-down
          </MeIcon>
        </span>
        Voltar
      </p>
    </Form>
    <div></div>
  </div>
</template>

<style lang="scss" scoped>
.login-select-installation {
  overflow-y: auto;

  @media (min-width: 993px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50vw;
  }

  &__return {
    width: 100%;
    max-width: 495px;
    margin: 1.5rem auto 0;
    padding-left: 1.5rem;

    display: flex;
    align-items: flex-end;

    color: #667080;
    font-size: 1rem;
    font-weight: 500;

    @media (min-width: 993px) {
      margin-top: 0;
    }

    &__icon {
      transform: rotate(90deg);
      font-size: 16px;
      padding-bottom: 3px;
      margin-right: 3px;
    }
  }

  &__container {
    width: 100%;
    max-width: 495px;
    margin: 0 auto;
    padding: 1.5rem;
  }

  &__metha-logo {
    width: 230px;
    margin: 0 auto;

    @media (min-width: 993px) {
      width: 205px;
      margin: 0 auto;
    }
  }

  &__title {
    color: #000;
    font-feature-settings: "calt" off;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    margin: 2.5rem 0 0.5rem;

    @media (min-width: 993px) {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: -0.72px;
      margin: 4rem 0 1.5rem;
    }
  }

  &__register {
    color: #6c757d;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &__installations-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    margin: 1.5rem 0 2rem;

    @media (min-width: 993px) {
      margin: 2.5rem 0;
    }
  }

  &__submit-button {
    width: 100%;
  }
}
</style>
