<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import { useUserStore } from "@/stores";

import { formatCPF } from "@/utils/format";
import { orderInstallations } from "@/utils/orderInstallations";

import InstallationCard from "@/components/molecules/InstallationCard.vue";

const userStore = useUserStore();

const orderedInstallations = computed(() => {
  return orderInstallations(userStore.installations);
});

onBeforeMount(() => {
  userStore.setPageTitle("Minhas instalações");
});
</script>

<template>
  <div class="installations-page">
    <section
      class="adc__invoices__section installations-page__client-information"
    >
      <p>
        <span class="installations-page__client-information__label">
          Cliente:
        </span>
        {{ userStore.name }}
      </p>
      <p>
        <span class="installations-page__client-information__label">
          Cpf:
        </span>
        {{ formatCPF(userStore.cpf) }}
      </p>
    </section>
    <section
      class="adc__invoices__section installations-page__installations-list"
    >
      <InstallationCard
        v-for="installation in orderedInstallations"
        :key="`installation-list-${installation.id}`"
        :installation="installation"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
.installations-page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding-bottom: 3rem;

  @media (min-width: 768px) {
    margin-top: -48px;
  }

  &__client-information {
    color: #000;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      box-shadow: unset;
      margin-top: -10px;
      padding-top: 0;
      padding-bottom: 40px;
      background: #ffcc12;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    &__label {
      font-weight: 400;
      text-transform: uppercase;
      margin-right: 5px;

      @media (min-width: 768px) {
        color: #6c757d;
      }
    }
  }

  &__installations-list {
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin-top: -48px;

    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (min-width: 768px) {
      margin-top: 0px;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  }
}
</style>
