<script setup lang="ts">
import { computed, defineProps } from "vue";
import { MeIcon } from "@/libs/metha-components";
import { getSupplierLogo } from "@/utils/supplierlogo";
import {
  getInstallationStatus,
  getInstallationStatusColor,
} from "@/utils/status";

const props = defineProps<{
  status: string;
  installationNumber: string;
  address: string;
  supplier: string;
}>();

const statusColor = computed(() => getInstallationStatusColor(props.status));

const supplierFullText = computed(() => {
  const suppliersText = {
    cemig: "CEMIG - Minas Gerais",
    "cpfl-paulista": "CPFL - São Paulo",
    "energisa-mt": "Energisa - Mato Grosso",
  };

  const key = props.supplier as keyof typeof suppliersText;

  return suppliersText[key] || suppliersText.cemig;
});

const supplierLogo = computed(() => {
  return getSupplierLogo(props.supplier);
});
</script>

<template>
  <div class="installation-info">
    <div class="installation-info__header">
      <p class="installation-info__header__label">
        <span class="installation-info__header__icon">
          <MeIcon>building</MeIcon>
        </span>
        <span>Instalação</span>
      </p>
      <div
        class="installation-info__header__status"
        :style="{
          color: `rgb(${statusColor})`,
          'background-color': `rgba(${statusColor}, 0.2)`,
        }"
      >
        <span class="installation-info__header__status__badge">&#9679;</span>
        {{ getInstallationStatus(status).toUpperCase() }}
      </div>
    </div>
    <div class="installation-info__content">
      <div class="installation-info__content__row">
        <p class="installation-info__content__installation-number">
          {{ installationNumber }}
        </p>
        <div class="installation-info__content__supplier-logo">
          <img :src="supplierLogo" :alt="`${supplierFullText} logo`" />
        </div>
      </div>
      <div>
        <p class="installation-info__content__label">Concessionária</p>
        <p class="installation-info__content__value">{{ supplierFullText }}</p>
      </div>
      <div>
        <p class="installation-info__content__label">Endereço</p>
        <p class="installation-info__content__value">
          {{ address }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.installation-info {
  display: flex;
  flex-direction: column;
  gap: 17px;

  @media (min-width: 768px) {
    gap: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &__icon {
      display: flex;
      align-items: center;
      font-size: 24px;

      @media (min-width: 768px) {
        font-size: 20px;
      }
    }

    &__label {
      display: flex;
      color: #6c757d;
      font-weight: 500;
      line-height: 24px;
      font-size: 18px;

      @media (min-width: 768px) {
        font-size: 20px;
      }
    }

    &__status {
      border: none;
      border-radius: 0.5rem;
      font-size: 14px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: -0.02em;

      display: flex;
      align-items: center;
      padding: 7px 10px;
      text-align: center;
      width: fit-content;
      height: min-content;

      &__badge {
        display: flex;
        flex-direction: column;
        padding-right: 0.25rem;
        padding-bottom: 1px;
        font-size: 10px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__row {
      display: flex;
      align-items: center;
    }

    &__installation-number {
      margin-right: 24px;

      font-weight: 700;
      color: #111111;
      font-size: 20px;
      line-height: 24px;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 36px;
      }
    }

    &__supplier-logo {
      width: 100%;
      max-width: 70px;
      height: 100%;
      max-height: 40px;
    }

    &__label {
      font-weight: 400;
      color: #6c757d;
      font-size: 16px;
      line-height: 20px;
    }

    &__value {
      font-weight: 400;
      color: #111111;
      font-size: 16px;
      line-height: 24px;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
</style>
