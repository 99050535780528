import { FlatInstallation } from "@/types/InstallationTypes";

const statusOrder = [
  "active",
  "approved",
  "under_cancellation",
  "suspended",
  "under_analysis",
  "canceled",
  "pending_agreement",
  "reproved",
  "pending_update",
  "contract_canceled",
  "inactive",
];

function sortByOrder(installation: FlatInstallation) {
  const value = statusOrder.indexOf(installation.status);

  return value !== -1 ? value : 12;
}

export function orderInstallations(installations: FlatInstallation[]) {
  const orderedInstallations = [...installations];

  orderedInstallations.sort(
    (prevInstallation, nextInstallation) =>
      sortByOrder(prevInstallation) - sortByOrder(nextInstallation)
  );

  return orderedInstallations;
}
