import { default as axios } from "axios";
import type { AxiosRequestConfig } from "axios";
import { AuthDTO, VerifyDTO, VerifyEmailDTO } from "./types";
import { useAuthStore } from "@/stores";

export type Query = Record<string, unknown>;

declare module "axios" {
  export interface AxiosRequestConfig {
    public?: boolean;
  }
}

axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}`;

axios.interceptors.request.use(async (req) => {
  if (!req.public && req.headers) {
    if (!req.headers.Authorization) {
      const authStore = useAuthStore();
      const token = authStore.get();
      if (token) {
        req.headers.Authorization = token;
      }
    }
  }

  return req;
});

async function checkCpf(
  cpf: string,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "GET",
    url: `/register/${cpf}`,
    params,
    ...config,
  });
}

async function verifySMS(
  data: VerifyDTO,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "POST",
    url: "/register/verify",
    data,
    params,
    ...config,
  });
}

async function verifyEmail(
  data: VerifyEmailDTO,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "POST",
    url: "/register/verify_email",
    data,
    params,
    ...config,
  });
}

async function authSMS(
  data: AuthDTO,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "POST",
    url: "/register/auth_sms",
    data: {
      ...data,
      skipBigBoost: true,
    },
    params,
    ...config,
  });
}

async function authEmail(
  data: AuthDTO,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "POST",
    url: "/register/auth",
    data: {
      ...data,
      skipBigBoost: true,
    },
    params,
    ...config,
  });
}

async function getUser(
  data: { cpf: string },
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "GET",
    url: `/register/${data.cpf}`,
    data,
    params,
    ...config,
  });
}

async function getInstallations(
  userId: string,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "GET",
    url: `/client-area/installation/${userId}`,
    params,
    ...config,
  });
}

async function getAllInvoices(
  parent_id: string | number,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "GET",
    url: `/invoice/?parent_id=${parent_id}`,
    params,
    ...config,
  });
}

async function getInvoiceById(
  id: string | number,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "GET",
    url: `/invoice/${id}`,
    params,
    ...config,
  });
}

async function getAuthChat(
  userId: string,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "POST",
    url: "/zendesk-chat-auth",
    data: { userId },
    params,
    ...config,
  });
}

export default {
  checkCpf,
  verifySMS,
  authSMS,
  verifyEmail,
  authEmail,
  getUser,
  getInstallations,
  getAllInvoices,
  getInvoiceById,
  getAuthChat,
};
