function formatDate(date: Date | string | number) {
  const rawDate: Date = new Date(date);

  return rawDate.toLocaleDateString("pt-BR", { timeZone: "gmt" });
}

const moneyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

function formatMoney(value = 0) {
  if (typeof value !== "number") return value;

  if (value < 0) value = 0;

  const moneyValue = moneyFormatter.format(value);

  return moneyValue;
}

const decimalFormatter = new Intl.NumberFormat("pt-BR", {
  maximumFractionDigits: 8,
});

function formatDecimal(value: number) {
  if (typeof value !== "number") return value;

  if (value < 0) value = 0;

  const decimalValue = decimalFormatter.format(value);

  return decimalValue;
}

function getMonthNameFromReferenceMonth(reference_month: string) {
  const [month] = reference_month.split("/");

  const monthName = {
    JAN: "Janeiro",
    FEV: "Fevereiro",
    MAR: "Março",
    ABR: "Abril",
    MAI: "Maio",
    JUN: "Junho",
    JUL: "Julho",
    AGO: "Agosto",
    SET: "Setembro",
    OUT: "Outubro",
    NOV: "Novembro",
    DEZ: "Dezembro",
  };

  if (Object.keys(monthName).includes(month)) {
    const key = month as keyof typeof monthName;
    return monthName[key];
  }

  return month;
}

function capitalize(value: string) {
  if (!value) return value;

  const words = value.split(" ");

  return words
    .map((word: string) => {
      const valueLowerCase = word.toLowerCase();

      if (word.length <= 1) return word;

      return valueLowerCase[0].toUpperCase() + valueLowerCase.slice(1);
    })
    .join(" ");
}

function formatCEP(cep: string | number) {
  const rawCep = String(cep).replace(/\D/, "");

  return rawCep.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
}

function formatCPF(cpf: string) {
  const strCPF = cpf.replace(/[^\d]+/g, "");

  if (strCPF == "" || strCPF.length != 11) return cpf;

  return strCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export {
  formatDate,
  formatMoney,
  formatDecimal,
  getMonthNameFromReferenceMonth,
  capitalize,
  formatCEP,
  formatCPF,
};
